.colorBlue{
  color: lightskyblue;
}

/*form send*/
.maxWith{
  width: 250px;
}

/*decoration text Main header*/
.descShadow{
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

/*h4 headers*/
.colorH{
  color: rgb(2, 89, 124);
}

.jumbotronTop{
  background-image: url("./photo/fotis.jpg");
  background-size: cover;
  background-position: center;
  color: white;
}